<template>
  <div>
    <c-card class="cardClassDetailInfo" :noHeader="true">
      <template slot="card-detail">
        <div class="col-6">
          <c-label-text title="사업장" :value="fireFighting.plantName"></c-label-text>
        </div>
        <div class="col-6">
          <c-label-text title="관리번호" :value="fireFighting.manageNo"></c-label-text>
        </div>
        <div class="col-6">
          <c-label-text title="설비유형" :value="fireFighting.sopFireFightingTypeName"></c-label-text>
        </div>
        <div class="col-6">
          <c-label-text title="설비종류" :value="fireFighting.sopFireFightingTypeName"></c-label-text>
        </div>
        <div class="col-12">
          <c-label-text title="소방시설 및 장비명" :value="fireFighting.fireFightingName"></c-label-text>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <c-table
          ref="table"
          title="점검이력"
          :columns="grid.columns"
          :data="fireFighting.histroy"
          :gridHeightAuto="true"
          :isExcelDown="false"
          :isFullScreen="false"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          @linkClick="linkClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <c-table
          ref="table"
          title="소방 이슈점검 이력 목록"
          :columns="gridIssueCheck.columns"
          :data="fireFighting.issueChecks"
          :gridHeight="gridHeight"
          @linkClick="linkClick"
        >
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingHistory',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
      }),
    },
    fireFighting: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',  // 소방설비 일련번호
        plantCd: null,  // 사업장
        plantName: null,  // 사업장
        sopFireFightingTypeCd: null,  // 유형
        sopFireFightingTypeName: null,  // 유형
        sopFireFightingKindCd: null,  // 점검 소방시설 종류
        sopFireFightingKindName: '',  // 점검 소방시설 종류
        manageNo: '',  // 관리번호
        fireFightingName: '',  // 소방설비명
        deptCd: '',  // 관리부서
        processCd: '',  // 관련공정
        sopFireFightinhReplacementCycleCd: '',  // 소방설비 교체 주기
        amount: '',  // 수량
        chmDangerMstId: '',  // 관련 위험물 저장소 일련번호
        location: '',  // 설비위치
        installDate: '',  // 설치일
        disposeFlag: 'N',  // 폐기여부
        disposeDate: '',  // 폐기일
        deleteFlag: 'N',  // 삭제여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        histroy: [],
        replacementHistroies: [],
        deleteReplacementHistroies: [],
        issueChecks: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorItems: [
        { stepperMstCd: 'MCSC000005', stepperMstNm: '계획수립', colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: '점검중', colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: '점검완료', colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: '지연', colorClass: 'red' },
      ],
      grid: {
        columns: [
          {
            name: 'checkName',
            field: 'checkName',
            label: '점검명',
            align: 'left',
            sortable: true,
            style: 'width: 40%',
            type: 'link'
          },
          {
            name: "sopFireFightingCheckTypeName",
            field: "sopFireFightingCheckTypeName",
            style: 'width:20%',
            label: "점검유형",
            align: "center",
            sortable: true,
          },
          {
            name: "checkPeriod",
            field: "checkPeriod",
            label: "점검기간",
            style: 'width:40%',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      gridIssueCheck: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: 'LBLPLANT',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 100px',
          // },
          {
            name: 'issueCheckName',
            field: 'issueCheckName',
            label: '점검명',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'link',
          },
          {
            name: 'yearmonth',
            field: 'yearmonth',
            label: '점검년월',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          // {
          //   name: 'deptName',
          //   field: 'deptName',
          //   label: '점검부서',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 120px',
          // },
          {
            name: 'sopFireFightingIssueStepName',
            field: 'sopFireFightingIssueStepName',
            label: '진행상태',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.min.equipment.plan.list.url;
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      if (col.name === 'checkName') {
        this.popupOptions.title = '소화설비 점검 상세';
        this.popupOptions.param = {
          sopFireFightingCheckId: row.sopFireFightingCheckId,
        };
        this.popupOptions.target = () => import(`${'./fireFightingCheckDetail.vue'}`);
        this.popupOptions.isFull = true;
      } else {
        this.popupOptions.title = '소방 이슈점검 상세';
        this.popupOptions.param = {
          sopFireFightingIssueCheckId: row.sopFireFightingIssueCheckId
        };
        this.popupOptions.target = () => import(`${'@/pages/fft/ise/fireFightingIssueCheckDetail.vue'}`);
        this.popupOptions.isFull = true;
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>